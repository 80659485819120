//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { required } from 'vuelidate/lib/validators';
import BaseButton from './BaseButton.vue';

const { mapFields } = createHelpers({
  getterType: 'topics/replies/getField',
  mutationType: 'topics/replies/updateField',
});

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  validations: {
    content: { required },
  },
  computed: {
    ...mapFields({
      content: 'replieForm.content',
    }),
  },
  methods: {
    reply() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$anyError && this.content !== '') {
        this.$store.dispatch('topics/replies/addReply', { $socket: this.$socket })
          .then(() => {
            this.content = '';
            this.$v.$reset();
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    cancel() {
      this.content = '';
    },
  },
};
