//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import BaseAvatar from "./BaseAvatar.vue";
import BaseButton from "./BaseButton.vue";
import Rejoinders from "./Rejoinders.vue";

export default {
  components: {
    BaseAvatar,
    BaseButton,
    Rejoinders,
  },
  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["call-reply"],
  data() {
    return {
      showConfirmDialog: false,
      content: "",
      editing: false,
      loading: false,
      comment: false,
      deleteAction: false,
      liked: false,
    };
  },
  computed: {
    like: {
      get() {
        return this.myLike(this.reply.id).length;
      },
      set() {
        if (this.myLike(this.reply.id).length) {
          this.$store.dispatch("topics/replies/likes/removeLike", {
            reply: this.reply,
            like: this.myLike(this.reply.id)[0],
            $socket: this.$socket,
          });
        } else {
          this.$store.dispatch("topics/replies/likes/createLike", {
            reply: this.reply,
            $socket: this.$socket,
          });
        }
      },
    },
    ...mapGetters({
      currentUser: "users/getCurrentUser",
      numberOfReplyLikes: "topics/replies/likes/getNumberOfReplyLikes",
      myLike: "topics/replies/likes/getMyLikeCurrentTopicByReplyId",
      rejoinders:
        "topics/replies/rejoinders/getMyRejoindersCurrentTopicByReplyId",
    }),
    hasBeenLiked() {
      return 0;
      // return this.myLikes.some((el) => el.replyId === this.reply.id);
    },
  },
  methods: {
    deleteReply() {
      this.$q.notify({
        type: "warning",
        textColor: "black",
        position: 'top-right',
        message: "Tem certeza que deseja excluir esse comentário?",
        actions: [
          {
            label: "Sim",
            color: "black",
            handler: () => this.$store.dispatch('topics/replies/deleteReply', { reply: this.reply, $socket: this.$socket, $q: this.$q }),
          },
          { label: "Não", color: "black", handler: () => {} },
        ],
      });
    },
    editReply() {
      this.loading = true;
    },
    likeReply() {
      if (this.myLike(this.reply.id).length) {
        this.$store.dispatch("topics/replies/likes/removeLike", {
          replyId: this.reply.id,
        });
      } else {
        this.$store.dispatch("topics/replies/likes/createLike", {
          replyId: this.reply.id,
        });
      }
    },
    canEditTopic() {
      if (
        this.currentUser !== null &&
        this.currentUser?.user?.id === this.reply?.user?.id
      ) {
        return true;
      }
      return false;
    },
  },
};
