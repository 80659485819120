//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RejoinderForm from "./RejoinderForm.vue";
import BaseAvatar from "./BaseAvatar.vue";

export default {
  name: "Rejoinder",
  components: {
    RejoinderForm,
    BaseAvatar,
  },
  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
    rejoinders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    deleteRejoinder(rejoinder) {
      this.$q.notify({
        type: "warning",
        textColor: "black",
        position: 'top-right',
        message: "Tem certeza que deseja excluir essa resposta?",
        actions: [
          {
            label: "Sim",
            color: "black",
            handler: () => this.$store.dispatch('topics/replies/rejoinders/deleteRejoinder', { rejoinder, reply: this.reply, $socket: this.$socket }),
          },
          { label: "Não", color: "black", handler: () => {} },
        ],
      });
    },
  },
};
