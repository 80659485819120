//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import BaseButton from '../components/BaseButton.vue';
import ReplyForm from '../components/ReplyForm.vue';
import Reply from '../components/Reply.vue';

export default {
  components: {
    BaseButton,
    ReplyForm,
    Reply,
  },
  emits: ['card-click'],
  data() {
    return {
      showConfirmDialog: false,
      support: null,
      isConnected: false,
      socketMessage: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      topic: 'topics/getCurrentTopic',
      supports: 'topics/supports/getInfoCurrentTopicSupports',
      replyes: 'topics/replies/getCurrentTopicReplyes',
      myVote: 'topics/supports/getMyVoteCurrentTopic',
      isLoggedIn: 'users/isLoggedIn',
      currentUser: 'users/getCurrentUser'
    }),
    owner() {
      if (this.currentUser.id === this.topic.userId) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$store
        .dispatch('topics/loadTopicId', { id: this.$route.params.topicId })
        .then(() => {
          this.$store.dispatch('topics/supports/loadSupportsByTopicId');
          this.$store.dispatch('topics/replies/loadRepliesByTopicId');
          this.$store.dispatch('topics/replies/likes/loadLikesByTopicId');
          this.$store.dispatch('topics/replies/rejoinders/loadRejoindersByTopicId');
          this.loading = false;
        });
  },
  methods: {
    setTopic() {
      this.$store.commit('topics/SET_CURRENT_TOPIC', this.topic);
      this.$store.commit('users/SET_SELECTED_FORM', 'topic-editor');
      this.$store.dispatch('topics/fetchStorage');
      
      this.$router.push(`/profile/topics/edit/${this.topic.id}`)
    },
    confirmSupport (triggerType){
      this.showConfirmDialog = true;
      this.support = triggerType;
    },
    computeSupport() {
      this.$store.dispatch('topics/supports/supportCurrentTopic', { supportType: this.support, $socket: this.$socket })
    },
    supportsPercentage(type) {
      const posAmount = parseInt(this.supports.positiveSupports, 10);
      const negAmount = parseInt(this.supports.negativeSupports, 10);
      const totalSupports = parseInt(posAmount + negAmount, 10);
      if (!totalSupports) {
        return 0;
      }
      if (type === true) {
        return parseInt((posAmount / totalSupports) * 100, 10);
      }
      return parseInt((negAmount / totalSupports) * 100, 10);
    },
  },
};
