//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapActions } from 'vuex';
// import { createHelpers } from 'vuex-map-fields';
import { required } from 'vuelidate/lib/validators';
import BaseButton from './BaseButton.vue';

// const { mapFields } = createHelpers({
//   getterType: 'topics/rejoinders/getField',
//   mutationType: 'topics/rejoinders/updateField',
// });

export default {
  components: {
    BaseButton,
  },
  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  validations: {
    content: { required },
  },
  computed: {
    content: {
      get() {
        return this.$store.getters['topics/replies/rejoinders/getRejoinderFormByReplyId'](this.reply.id);
      },
      set(value) {
        this.$store.commit('topics/replies/rejoinders/SET_REJOINDER_FORM_BY_REPLY_ID', { replyId: this.reply.id, content: value });
      },
    },
  },
  created() {},
  methods: {
    rejoin() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$anyError && this.content !== '') {
        this.$store.dispatch('topics/replies/rejoinders/addRejoinder', { rejoinder: { replyId: this.reply.id, content: this.content }, reply: this.reply, $socket: this.$socket }).then(() => {
          this.content = '';
          this.$v.$reset();
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    cancel() {
      this.content = '';
    },
  },
};
