//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    avatar: {
      type: String,
      default: '',
    },
  },
  computed: {
    categoryColor () {
      return this.$store.getters['categories/getCategoryById'](this.$store.getters['users/getCurrentUser'].categoryId).color;
    }
  }
};
